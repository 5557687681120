import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { CompanyEntity, EntityRef, PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {
  IconArrowBackUp,
  IconCoins,
  IconChartLine,
  IconChartHistogram,
  IconCopy,
  IconUsers,
  IconGrave
} from '@tabler/icons-react';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import IconBuildings from '@/assets/custom/IconBuildings';
import CountriesFlags from '@/components/countriesflags';
import { CompanyGetter, getEntity, PersonGetter } from '@/main/providers/getter';
import Accuracy from '../../../../../components/chips/accuracy';

function TreeEntityCard(props: { entity: Omit<EntityRef, 'name'> & { name?: string | null }, children?: ReactNode, roles?: string[] }): ReactElement {
  const [entity, setEntity] = useState<PersonEntity | CompanyEntity>();
  const { t } = useTranslation();
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const companyGetter = useMemo(() => new CompanyGetter({
    data: entity as CompanyEntity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);
  const personGetter = useMemo(() => new PersonGetter({
    data: entity as PersonEntity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);

  useEffect(() => {
    getEntity<PersonEntity | CompanyEntity>(props.entity)
      .then(res => setEntity(res));
  }, [props.entity]);

  const Person = useMemo(() => (
    <Paper variant='hoverElevation2' sx={{ boxShadow: 1, cursor: 'pointer', width: '500px', height: '108px', p: 1, pl: 4, pr: 4 }}>
      <Stack spacing={1} height='100%' justifyContent='space-between' pb={1}>
        <Stack height={24} spacing={1} direction='row' alignItems='center' width='calc(100% + 16px)' sx={{ ml: '-8px !important' }}>
          <Tooltip title={props.entity.name || props.entity.entityId} arrow placement='top'>
            <Typography variant='body1' mr={2} fontWeight={500} noWrap>
              {props.entity.name || props.entity.entityId}
            </Typography>
          </Tooltip>
          <Tooltip
            title={copied ? t('common.utils.copied') : `${t('common.utils.copy')}`}
            arrow
            placement='top'
            onOpen={() => setCopied(false)}
          >
            <IconCopy
              style={{
                marginLeft: '2px',
                marginRight: '6px'
              }}
              color={theme.palette.grey['600']}
              size={12}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(props.entity.name || props.entity.entityId)
                  .then(() => {
                    setCopied(true);
                  });
              }}
            />
          </Tooltip>
          {entity &&
            <>
              <Tooltip
                title={`${t('entities.companies.label')}: ${t('common.utils.active')} / ${t('common.utils.total')}`}
                arrow
                placement='top'
              >
                <Chip
                  sx={{
                    transform: 'scale(0.8)'
                  }}
                  color={
                    ((personGetter.getNbCompanies(true) as number)
                      - (personGetter.getNbActiveCompanies(true) as number))
                    <= (personGetter.getNbActiveCompanies(true) as number) ?
                      'success' : 'error'
                  }
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconBuildings
                        size={14}
                        color={
                          ((personGetter.getNbCompanies(true) as number)
                            - (personGetter.getNbActiveCompanies(true) as number))
                          <= (personGetter.getNbActiveCompanies(true) as number) ?
                            theme.palette.success.dark : theme.palette.error.dark
                        }
                      />
                      <Typography>
                        {`${personGetter.getNbActiveCompanies(true)}/${personGetter.getNbCompanies(true)}`}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip
                title={`${t('entities.persons.alterLabels.relations')}: ${t('common.utils.current')} / ${t('common.utils.total')}`}
                arrow
                placement='top'
              >
                <Chip
                  sx={{
                    ml: '-8px !important',
                    transform: 'scale(0.8)'
                  }}
                  color={
                    ((personGetter.getNbRelations() as number)
                      - (personGetter.getNbCurrentRelations() as number))
                    <= (personGetter.getNbCurrentRelations() as number) ?
                      'success' : 'error'
                  }
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconUsers
                        size={14}
                        color={
                          ((personGetter.getNbRelations() as number)
                            - (personGetter.getNbCurrentRelations() as number))
                          <= (personGetter.getNbCurrentRelations() as number) ?
                            theme.palette.success.dark : theme.palette.error.dark
                        }
                      />
                      <Typography>
                        {`${personGetter.getNbCurrentRelations()}/${personGetter.getNbRelations()}`}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              {personGetter.getIsDead() &&
                <Tooltip
                  title={
                    <Stack>
                      <Typography>
                        {t('common.utils.deceased')} {personGetter.getDeathDate()}
                        <Accuracy accuracy={personGetter.getDeathMatchAccuracy()}/>
                      </Typography>
                    </Stack>
                  }
                  arrow
                  placement='top'
                >
                  <Chip
                    sx={{
                      ml: '-8px !important',
                      transform: 'scale(0.8)'
                    }}
                    color='error'
                    label={
                      <Stack spacing={1} direction='row' alignItems='center'>
                        <IconGrave size={16} color={ theme.palette.error.dark}/>
                      </Stack>
                    }
                    size='small'
                  />
                </Tooltip>
            }
            {
              personGetter.getHighestPoliticalExposureLevel()
                ? <Chip
                  sx={{ transform: 'scale(0.8)' }}
                  key='pep-chip'
                  size='small'
                  color='error'
                  label={`${t(`politicalExposure.label`)}:${t(`politicalExposure.values.${personGetter.getHighestPoliticalExposureLevel()}`)}`}
                />
                : <></>
            }
            </>
          }
        </Stack>
        {entity &&
          <>
            <Stack spacing={2} direction='row' alignItems='center'>
              <Tooltip title={t('common.financial.weasse.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '160px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconCoins size={14} />
                      <Typography>
                        {personGetter.getWeasse()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.weasseMax.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '160px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconCoins size={14} />
                      <Typography>
                        {personGetter.getWeasseMax()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.fortune.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '160px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartHistogram size={14} />
                      <Typography>
                        {personGetter.getFortune()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
            </Stack>
            <Stack spacing={2} direction='row' alignItems='center' width='calc(100% + 16px)' sx={{ ml: '-8px !important' }}>
              <Tooltip title={t('common.financial.turnover.sum')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconArrowBackUp size={14} />
                      <Typography>
                        {personGetter.getTurnoverSum()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.capital.sum')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconCoins size={14} />
                      <Typography>
                        {personGetter.getCapitalSum()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.ebitda.sum')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartHistogram size={14} />
                      <Typography>
                        {personGetter.getEbitdaSum()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.valuation.sum')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartLine size={14} />
                      <Typography>
                        {personGetter.getValuationSum()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
            </Stack>
          </>
        }
      </Stack>
    </Paper>
  ), [entity, copied]);

  const Company = useMemo(() => (
    <Paper variant='hoverElevation2' sx={{ boxShadow: 1, cursor: 'pointer', width: '500px', height: props.children ? '188px' : '108px', p: 1, pl: 4, pr: 4, borderStyle: props.roles?.includes('adv') ? 'dashed' : undefined, border: props.roles?.includes('adv') ? undefined : 1, borderColor: props.roles?.includes('adv') ? undefined : theme.palette.primary.main }}>
      <Stack spacing={1} height='100%' justifyContent='space-between' pb={1}>
        <Stack height={24} spacing={1} direction='row' alignItems='center' width='calc(100% + 16px)' sx={{ ml: '-8px !important' }}>
          <Tooltip title={props.entity.name || props.entity.entityId} arrow placement='top'>
            <Typography variant='body1' mr={2} fontWeight={500} noWrap>
              {props.entity.name || props.entity.entityId}
            </Typography>
          </Tooltip>
          <Tooltip
            title={copied ? t('common.utils.copied') : `${t('common.utils.copy')}`}
            arrow
            placement='top'
            onOpen={() => setCopied(false)}
          >
            <IconCopy
              style={{
                marginLeft: '2px',
                marginRight: '6px'
              }}
              color={theme.palette.grey['600']}
              size={14}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(props.entity.name || props.entity.entityId)
                  .then(() => {
                    setCopied(true);
                  });
              }}
            />
          </Tooltip>
          {entity && companyGetter.getCountryCode(true) &&
            <Tooltip title={companyGetter.getCountry()} placement='top' arrow>
              <Box minWidth='max-content' mt='2px !important' ml='0 !important' borderRadius={1}>
                {CountriesFlags({ countries: personGetter.getCountriesCodes() })}
              </Box>
            </Tooltip>
          }
          {entity &&
            <>
              <Chip
                sx={{
                  transform: 'scale(0.8)'
                }}
                label={companyGetter.getState()}
                color={companyGetter.getState() === 'open' ? 'success' : 'error'}
                size='small'
              />
              {entity.tags?.find(tag => tag.family === 'companyType' && tag.value === 'listedCompany') &&
                <Tooltip title={t('entities.tags.listed.company')} placement='top' arrow>
                  <Chip
                    label='L'
                    sx={{
                      transform: 'scale(0.8)',
                      '& .MuiChip-labelSmall': {
                        fontWeight: '600'
                      }
                    }}
                    color='error'
                    size='small'
                  />
                </Tooltip>
            }
            {
              companyGetter.getHighestPoliticalExposureLevel()
                ? <Chip
                  sx={{
                    transform: 'scale(0.8)'
                  }}
                  key='pep-chip'
                  size='small'
                  color='error'
                  label={`${t(`politicalExposure.label`)}:${t(`politicalExposure.values.${companyGetter.getHighestPoliticalExposureLevel()}`)}`}
                />
                : <></>
            }
            </>
          }
        </Stack>
        {props.children &&
          <Box height='80px'>
            {props.children}
          </Box>
        }
        {entity &&
          <>
            <Typography variant='body2' noWrap pl={4} pr={4}>
              {companyGetter.getLegalForm()}
            </Typography>
            <Typography variant='body2' noWrap pl={4} pr={4}>
              {companyGetter.getActivitySector()}
            </Typography>
            <Stack spacing={2} direction='row' alignItems='center' width='calc(100% + 16px)' sx={{ ml: '-8px !important' }}>
              <Tooltip title={t('common.financial.turnover.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconArrowBackUp size={14} />
                      <Typography>
                        {companyGetter.getTurnover()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.capital.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconCoins size={14} />
                      <Typography>
                        {companyGetter.getCapital()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.ebitda.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartHistogram size={14} />
                      <Typography>
                        {companyGetter.getEbitda()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
              <Tooltip title={t('common.financial.valuation.label')} arrow placement='top'>
                <Chip
                  sx={{ width: '115px', mr: '-8px' }}
                  label={
                    <Stack spacing={1} direction='row' alignItems='center'>
                      <IconChartLine size={14} />
                      <Typography>
                        {companyGetter.getValuation()}
                      </Typography>
                    </Stack>
                  }
                  size='small'
                />
              </Tooltip>
            </Stack>
          </>
        }
      </Stack>
    </Paper>
  ), [entity, copied]);

  return (
    entity?.entityType === 'deecCompany' && Company || entity?.entityType === 'deecPerson' && Person ||
    <Paper variant='hoverElevation2' sx={{ boxShadow: 1, cursor: 'pointer', width: '500px', height: '108px', p: 1, pl: 4, pr: 4 }}>
      <Stack spacing={2} height='100%' justifyContent='space-evenly'>
        <Tooltip title={props.entity.name || props.entity.entityId} arrow placement='top'>
          <Typography variant='body2' mr={2} fontWeight={500} noWrap>
            {props.entity.name || props.entity.entityId}
          </Typography>
        </Tooltip>
        <LinearProgress variant='indeterminate' sx={{ borderRadius: 2 }} />
        <Box height={16} />
      </Stack>
    </Paper>
  );
}

export default TreeEntityCard;
