import React, { createRef, ReactElement, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { IconFilter, IconFilterOff, IconRotate, IconUsers, IconUsersPlus } from '@tabler/icons-react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import CustomSwitchIconButton from '@/components/switch';
import IconBuildings from '@/assets/custom/IconBuildings';
import { makeFindOptions } from '@/utils';
import SearchBar from './searchbar';
import SearchAutocomplete from './autocomplete';
import BaseDnaEntitiesService from '../../services/entities';
import PersonList from '../../../../containers/lists/person';
import CompanyList from '../../../../containers/lists/company';
import SearchFilters from './filters';
import dnaConfig from '../../../../../config/dna.config.json';
import { getEntityPath } from '@/main/providers/getter';
import LoadingList from '@/components/loading/list';

function SearchEngine(props: { noActions?: boolean, link?: ((id: string, entityType: string) => string) | string, onlySearchBar?: boolean }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchEntityTypeFromParams = searchParams.get('searchEntityType');
  const [searchEntityType, setSearchEntityType] = useState<'deecPerson' | 'deecCompany'>((searchEntityTypeFromParams === 'deecPerson' || searchEntityTypeFromParams === 'deecCompany') ? searchEntityTypeFromParams : 'deecPerson');
  const [focus, setFocus] = useState(false);
  const [query, setQuery] = useState<string | undefined>(searchParams.get('query') || undefined);
  const [disableSearchBar, setDisableSearchBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openFilters, setOpenFilters] = useState<boolean>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popperWidth, setPopperWidth] = useState(0);
  const [results, setResults] = useState<(PersonEntity | CompanyEntity)[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [selectedFromAutocomplete, setSelectedFromAutocomplete] = useState<string>();
  const [selected, setSelected] = useState<string[]>([]);
  const [entityService, setEntityService] = useState<BaseDnaEntitiesService<PersonEntity | CompanyEntity>>(
    searchParams.get('searchEntityType') === 'deecCompany' ?
      new BaseDnaEntitiesService<CompanyEntity>({ entityType: 'company' }) :
      new BaseDnaEntitiesService<PersonEntity>({ entityType: 'person' })
  );
  const paperRef = createRef<HTMLDivElement>();
  const navigate = useNavigate();

  const callResults = (customValue?: string) => {
    const value = customValue || searchParams.get('query');

    setQuery(value || undefined);
    setDisableSearchBar(true);
    setLoading(true);
    entityService.getAll(makeFindOptions({ searchParams, filtersToAdd: [{ scope: 'fe_name', id: value || '', value }] }))
      .then((res) => {
        setLoading(false);
        setTotalCount(res.totalCount);
        if (res.data) {
          setResults(res.data);
        }
      })
      .catch(() => {
        setTotalCount(0);
        setLoading(false);
        setResults([]);
      });
  };

  const updateQuery = (value: string | undefined) => {
    setQuery(value);
    setDisableSearchBar(false);
    setLoading(false);
  };

  const onSubmit = (value?: string) => {
    if (value) {
      searchParams.set('query', value);
    }
    if (searchParams.get('page')) {
      searchParams.delete('page');
    }
    if (searchParams.get('pageSize')) {
      searchParams.delete('pageSize');
    }

    setSearchParams(searchParams);
  };

  const onSelect = (entity: string) => {
    navigate(getEntityPath({ entityType: searchEntityType, entityId: entity }));
  };

  const setSearchEntityTypeParam = () => {
    searchParams.forEach((v, key) => {
      if (key.startsWith('s_') || (key.startsWith('f') && key.includes('_')) || key === 'page' || key === 'pageSize' || key === 'searchEntityType') {
        searchParams.delete(key);
      }
    });

    switch (searchEntityType) {
    case 'deecPerson':
      setEntityService(new BaseDnaEntitiesService<PersonEntity>({ entityType: 'person' }));
      searchParams.set('searchEntityType', 'deecPerson');
      break;
    case 'deecCompany':
      setEntityService(new BaseDnaEntitiesService<CompanyEntity>({ entityType: 'company' }));
      searchParams.set('searchEntityType', 'deecCompany');
      break;
    default:
      setEntityService(new BaseDnaEntitiesService<PersonEntity>({ entityType: 'person' }));
      searchParams.set('searchEntityType', 'deecPerson');
      break;
    }
    setSearchParams(searchParams);
  };

  const onReset = () => {
    navigate('/search');
  };

  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (key.startsWith('f') && key.includes('_')) {
        setOpenFilters(true);
      }
    });
  }, []);

  useEffect(() => {
    const tmpPage = searchParams.get('page');
    const tmpPageSize = searchParams.get('pageSize');

    if (tmpPage) {
      // setPage(parseInt(tmpPage, 10));
    }
    if (tmpPageSize) {
      // setPageSize(parseInt(tmpPageSize, 10));
    }
    callResults();
  }, [searchParams]);

  useEffect(() => {
    if (searchEntityType !== searchParams.get('searchEntityType')) {
      setTotalCount(undefined);
      setResults([]);
      setDisableSearchBar(false);
    }
    setSearchEntityTypeParam();
  }, [searchEntityType, openFilters]);

  useEffect(() => {
    if (paperRef.current) {
      setPopperWidth(paperRef.current.clientWidth);
      setAnchorEl(paperRef.current);
    }
  }, [paperRef]);

  useEffect(() => {
    setSelectedFromAutocomplete(undefined);
  }, [query]);

  return (
    <Box width='100%' height='100%'>
      <Stack spacing={6} height='100%'>
        <Stack
          spacing={2}
          direction='row'
          sx={{
            mt: openFilters || results.length > 0 ? 0 : '15%',
            transition: 'margin-top 0.4s ease-in-out'
          }}
          justifyContent='center'
          alignItems='center'
        >
          <Paper
            ref={paperRef}
            variant='hoverElevation2'
            sx={{
              p: 1,
              pl: 4,
              pr: 4,
              opacity: focus || query ? 1 : 0.8,
              borderRadius: 2,
              boxShadow: focus || query ? 2 : 0,
              minWidth: '600px',
              width: '80%',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                opacity: 1,
                transition: 'all 0.2s ease-in-out'
              }
            }}
          >
            <Stack
              direction='row'
              spacing={4}
              divider={<Divider orientation='vertical' flexItem sx={{ mt: '6px !important', mb: '6px !important' }} />}
              alignItems='center'
            >
              <CustomSwitchIconButton
                style={{
                  marginRight: '4px',
                  border: 'none'
                }}
                label1='deecPerson'
                label2='deecCompany'
                label1Icon={<IconUsers size={20} />}
                label2Icon={
                  <Stack direction='row' width={20}>
                    <Box
                      sx={{
                        opacity: searchEntityType === 'deecCompany' ? 1 : 0,
                        ml: '10px !important',
                        transition: 'opacity 0.2s ease-out'
                      }}
                    >
                      <IconBuildings color={theme.palette.primary.contrastText} size={20} />
                    </Box>
                    <Box
                      sx={{
                        opacity: searchEntityType === 'deecCompany' ? 0 : 1,
                        ml: '-20px !important',
                        transition: 'opacity 0.2s ease-out'
                      }}
                    >
                      <IconBuildings size={20} />
                    </Box>
                  </Stack>
                }
                value={searchEntityType}
                setValue={value => setSearchEntityType((value === 'deecPerson' || value === 'deecCompany') ? value : 'deecPerson')}
                noBackground
              />
              <SearchBar
                type={searchEntityType}
                focus={focus}
                setFocus={setFocus}
                query={query}
                setQuery={updateQuery}
                onSubmit={(value?: string) => {
                  if (props.onlySearchBar) {
                    navigate('/search');
                  }
                  onSubmit(value);
                }}
                loading={loading}
                selectedFromAutocomplete={selectedFromAutocomplete}
                hideSubmit={openFilters}
                disabled={disableSearchBar}
              />
              {!props.onlySearchBar &&
                <Box ml='8px !important'>
                  <Button variant='textIcon' onClick={() => setOpenFilters(!openFilters)}>
                    {openFilters ?
                      <IconFilterOff /> :
                      <IconFilter />
                    }
                  </Button>
                </Box>
              }
            </Stack>
          </Paper>
          <Tooltip title={t('common.utils.reset')} placement='top' arrow>
            <Button
              variant='textIcon'
              sx={{
                transform: 'rotate(180deg)',
                opacity: 0.8
              }}
              onClick={() => onReset()}
            >
              <IconRotate />
            </Button>
          </Tooltip>
          <SearchAutocomplete
            query={query}
            focus={focus}
            service={entityService}
            searchEntityType={searchEntityType}
            anchorEl={anchorEl}
            popperWidth={popperWidth}
            select={selection => onSelect(selection)}
            submitted={loading}
            setSelectedFromAutocomplete={setSelectedFromAutocomplete}
          />
        </Stack>
        {openFilters &&
          <SearchFilters
            type={searchEntityType}
            query={query}
            searchEntityType={searchEntityType}
            onSubmit={onSubmit}
            loading={loading}
          />
        }
        {totalCount !== undefined && !props.onlySearchBar ?
          <Stack spacing={2} sx={{ zIndex: 99, height: 'calc(100% - 90px)' }}>
            {searchEntityType === 'deecPerson' && results[0]?.entityType === 'deecPerson' &&
              <PersonList
                data={results as PersonEntity[]}
                link={props.link}
                totalCount={totalCount}
                selected={props.noActions ? undefined : selected}
                setSelected={props.noActions ? undefined : setSelected}
                actions={!props.noActions ? [
                  <Button
                    variant='contained'
                    startIcon={<IconUsersPlus size={16} />}
                    href={dnaConfig.routes.portfolios.create.relativeUrl}
                    sx={{
                      ml: 'auto !important'
                    }}
                  >
                    {t('folder.createFolder')}
                  </Button>
                ] : undefined}
              />
            }
            {searchEntityType === 'deecCompany' && results[0]?.entityType === 'deecCompany' &&
              <CompanyList
                data={results as CompanyEntity[]}
                link={props.link}
                totalCount={totalCount}
                selected={props.noActions ? undefined : selected}
                setSelected={props.noActions ? undefined : setSelected}
                actions={!props.noActions ? [
                  <Button
                    variant='contained'
                    startIcon={<IconUsersPlus size={16} />}
                    href={dnaConfig.routes.portfolios.create.relativeUrl}
                    sx={{
                      ml: 'auto !important'
                    }}
                  >
                    {t('folder.createFolder')}
                  </Button>
                ] : undefined}
              />
            }
          </Stack> :
          <LoadingList />
        }
      </Stack>
    </Box>
  );
}

export default SearchEngine;
