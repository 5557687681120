import React, { ReactElement } from 'react';
import { Card, CardActionArea, CardContent, CardHeader } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { CardHeaderOwnProps } from '@mui/material/CardHeader';
import { baseChartHeight, baseChartWidth } from '@/components/charts/utils';

export interface BaseWidgetProp extends CardHeaderOwnProps {
  ref?: React.RefObject<HTMLDivElement>,
  link?: string,
  maxWidth?: number,
  fullHeight?: boolean,
  fullWidth?: boolean,
  chart?: boolean,
  size?: 'large' | 'medium' | 'small',
  bgColor?: string,
  children?: ReactElement
}

function BaseWidget(props: BaseWidgetProp): ReactElement {
  const navigate = useNavigate();

  return (
    <Card
      ref={props.ref}
      onClick={() => (props.link ? navigate(props.link) : undefined)}
      sx={{
        p: 0,
        alignItems: 'center',
        cursor: props.link ? 'pointer' : '',
        display: 'flex',
        height: props.fullHeight ? '100%' : undefined,
        width: props.fullWidth ? '100%' : undefined,
        maxWidth: props.maxWidth,
        minWidth: 'min-content',
        bgcolor: props.bgColor
      }}
    >
      {props.link ?
        <CardActionArea
          sx={{
            height: '100%',
            overflow: 'visible',
            minWidth: 'min-content'
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateRows: 'min-content auto',
              alignItems: 'flex-start',
              height: '100%',
              width: '100%'
            }}
          >
            {props.title
              ? <CardHeader {...props} sx={{ p: typeof props.title === 'string' ? 2 : undefined }} />
              : null
            }
            <CardContent
              sx={{
                p: 2,
                pb: '8px !important',
                height: '100%',
                overflow: 'visible',
                minWidth: props.chart ? props.size === 'small' ? baseChartWidth / 1.4 : baseChartWidth : 'min-content',
                minHeight: props.chart ? baseChartHeight / 1.4 : undefined
              }}
            >
              {props.children}
            </CardContent>
          </Box>
        </CardActionArea> :
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            alignItems: 'flex-start',
            height: '100%',
            width: '100%',
            overflow: 'visible',
            minWidth: 'min-content'
          }}
        >
          {props.title
            ? <CardHeader {...props} sx={{ p: typeof props.title === 'string' ? 2 : undefined }} />
            : null
          }
          <CardContent
            sx={{
              p: 2,
              pb: '8px !important',
              height: '100%',
              minWidth: props.chart ? props.size === 'small' ? baseChartWidth / 1.4 : baseChartWidth : 'min-content',
              minHeight: props.chart ? baseChartHeight / 1.4 : undefined
            }}
          >
            {props.children}
          </CardContent>
        </Box>
      }
    </Card>
  );
}

export default BaseWidget;
