import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { IconChevronDown, IconInfoCircle } from '@tabler/icons-react';
import { SPECIAL_COUNTRIES_LISTS_TO_DISPLAY } from '@deecision/dna-interfaces';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CountriesFlags from '@/components/countriesflags';
import TitleComponent from '@/components/title';
import { getCountryName } from '@/utils';
import BaseWidget from '@/main/containers/widgets/base-widget';

function VeegilenzCountriesLists(): ReactElement {
  const { t, i18n } = useTranslation();

  return (
    <BaseWidget
      title={
        <TitleComponent
          title={t('veegilenz.lists.countries.label')}
          icon={IconInfoCircle}
          size='small'
        />
      }
      bgColor='transparent'
      size='small'
    >
      <Stack>
        {Object.keys(SPECIAL_COUNTRIES_LISTS_TO_DISPLAY).map(listId => (
          <Accordion key={listId} sx={{ bgcolor: 'transparent', border: 'none', width: '100%' }}>
            <AccordionSummary expandIcon={<IconChevronDown />} disabled={SPECIAL_COUNTRIES_LISTS_TO_DISPLAY[listId as keyof typeof SPECIAL_COUNTRIES_LISTS_TO_DISPLAY].length === 0}>
              {`${t(`veegilenz.lists.countries.${listId}`)} (${SPECIAL_COUNTRIES_LISTS_TO_DISPLAY[listId as keyof typeof SPECIAL_COUNTRIES_LISTS_TO_DISPLAY].length})`}
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                {SPECIAL_COUNTRIES_LISTS_TO_DISPLAY[listId as keyof typeof SPECIAL_COUNTRIES_LISTS_TO_DISPLAY].map(country => (
                  <Stack key={country} direction='row' alignItems='center' spacing={1}>
                    <CountriesFlags countries={[country]} key={country} />
                    <Typography variant='h5'>{getCountryName(country, i18n.language)}</Typography>
                  </Stack>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </BaseWidget>
  );
}

export default VeegilenzCountriesLists;
