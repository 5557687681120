import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { CompanyEntity } from '@deecision/dna-interfaces';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid2';
import { startCase } from 'lodash';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import { useTheme } from '@mui/material';
import CountriesFlags from '@/components/countriesflags';
import { currencyFormatter, CurrencyFormatterProps } from '@/utils';
import { CompanyGetter, makeRes } from '../../providers/getter';
import EvenOddTable from '@/components/tables/evenodd/table';
import { Column } from '@/components/tables/types';
import dnaConfig from '../../../config/dna.config.json';
import { ListProps } from './types';
import Status from '../../../components/status';

function CompanyList(props: ListProps<CompanyEntity>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'name',
      label: t('common.identity.name'),
      sort: !props.disableSort && !props.localSort ? 'name' : undefined,
      sortPath: 'props.children',
      minWidth: 240,
      noMaxWidth: true
    },
    {
      id: 'veegilenz',
      label: t('veegilenz.label'),
      sort: !props.disableSort && !props.localSort ? 'dv_veegilenzLevel' : undefined,
      align: 'center'
    },
    {
      id: 'tags',
      label: t('common.utils.tags')
    },
    {
      id: 'politicalExposure',
      label: t('politicalExposure.label'),
      sort: !props.disableSort && !props.localSort ? 'dv_politicalExposureLevel' : undefined,
    },
    {
      id: 'residence',
      label: t('common.identity.countries')
    },
    {
      id: 'nationalId',
      label: t('common.codes.nationalId')
    },
    {
      id: 'creationDate',
      label: t('common.dates.creationDate')
    },
    {
      id: 'codeApe',
      label: t('common.utils.activitySector'),
      sort: !props.disableSort && !props.localSort ? 'codeApe' : undefined,
      tooltip: true
    },
    {
      id: 'legalStatus',
      label: t('common.utils.legalForm'),
      sort: !props.disableSort && !props.localSort ? 'legalForm' : undefined,
      tooltip: true
    },
    {
      id: 'dismemberment',
      label: t('patrimonialComplexity.nbMemberInDismemberment.label'),
      align: 'right',
      render: value => makeRes(value),
      sort: !props.disableSort && !props.localSort ? 'dv_asset_complexity_nb_member_in_dismemberment' : undefined
    },
    {
      id: 'turnover',
      label: t('common.financial.turnover.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comp_turnover' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'ebitda',
      label: t('common.financial.ebitda.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comp_ebitda' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'capital',
      label: t('common.financial.capital.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comp_capital' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'valuation',
      label: t('common.financial.valuation.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comp_valuation' : undefined,
      render: value => currencyFormatter({ value: value as number })
    }
  ];

  const rows = props.data.map((company) => {
    const companyGetter = new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') });

    return {
      id: company.entityId,
      name:
      <Link
        href={typeof props.link === 'string' ? props.link : props.link?.(company.entityId as string, 'deecCompany') || `${dnaConfig.routes.companies.relativeUrl}/${company.entityId as string}`}
        sx={{ whiteSpace: 'nowrap', fontSize: 14 }}
      >
        {companyGetter.getName()}
      </Link>,
      veegilenz: companyGetter.getVeegilenzLevel() !== t('common.utils.unknown') ?
        <Tooltip
          title={
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography variant='body2'>{t(`veegilenz.reason.${companyGetter.getVeegilenzReason() || 'noReason'}`)}</Typography>
              <Status
                statusAsInt={
                  companyGetter.getVeegilenzReason() === 'atLeastOneBlackFact' && 3 ||
                  companyGetter.getVeegilenzReason() === 'atLeastOneRedFact' && 2 ||
                  companyGetter.getVeegilenzReason() === 'atLeastOneOrangeFact' && 1 ||
                  companyGetter.getVeegilenzReason() === 'moreThanTreeOrangeFacts' && 1 ||
                  companyGetter.getVeegilenzReason() === 'onlyGreenFacts' && 0 ||
                  0
                }
                size={16}
              />
            </Stack>
          }
          arrow
          placement='top'
        >
          <Box>
            <Status statusAsInt={companyGetter.getVeegilenzLevel(true) as number} size={24} />
          </Box>
        </Tooltip> : t('common.utils.unknown'),
      tags: <Stack direction='row' spacing={2} maxWidth={340} flexWrap='nowrap' useFlexGap>
        {companyGetter.getTags().filter(tag => tag.family !== 'batchTag').map(tag =>
          <Tooltip key={`${tag.value}-${tag.family}`} title={tag.family === 'companyType' && tag.value === 'listedCompany' ? t('entities.tags.listed.company') : t(`tags.families.${tag.family}`)} arrow placement='top'>
            {tag.family === 'companyType' && tag.value === 'listedCompany' ?
              <Chip sx={{ '& .MuiChip-labelSmall': { fontWeight: '600' } }} color='error' size='small' label='L' /> :
              <Chip size='small' label={t(`tags.values.${tag.value}`)} />
            }
          </Tooltip>
        )}
        {companyGetter.getSanctions().map(sanction => (
          <Tooltip title={t('tags.otherTags.sanctionType')} arrow placement='top'>
            <Chip
              sx={{
                ml: '-4px !important',
                transform: 'scale(0.8)'
              }}
              color='error'
              label={
                <Stack spacing={1} direction='row' alignItems='center'>
                  <IconAlertTriangleFilled
                    size={16}
                    color={ theme.palette.error.dark}
                  />
                  <Typography fontSize={15}>{t(`veegilenz.sanctionType.${sanction}`)}</Typography>
                </Stack>
              }
              size='small'
            />
          </Tooltip>
        ))}
      </Stack>,
    politicalExposure:
      <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
        {
          companyGetter.getPoliticalExposureLevels().map(exposureLevel =>
            <Chip color='error' size='small' label={t(`politicalExposure.values.${exposureLevel}`)} />
          )
        }
        </Stack>,
      residence:
    <Tooltip
      key='common.identity.country'
      title={startCase(companyGetter.getCountry()?.toLowerCase())}
      arrow
      placement='top'
    >
      <Grid container size='grow' maxWidth='200px' justifyContent='center' spacing={1}>
        {CountriesFlags({ countries: [companyGetter.getCountryCode() || ''], size: 'small' })}
      </Grid>
    </Tooltip>,
      nationalId: companyGetter.getSiren(),
      creationDate: companyGetter.getCreationDate(),
      codeApe: companyGetter.getActivitySector(),
      legalStatus: `${companyGetter.getLegalForm()} (${companyGetter.getLegalForm(true)})`,
      dismemberment: companyGetter.getIsDismemberment() ? companyGetter.getNbMembers(true) : undefined,
      turnover: companyGetter.getTurnover(true),
      ebitda: companyGetter.getEbitda(true),
      capital: companyGetter.getCapital(true),
      valuation: companyGetter.getValuation(true)
    };
  });

  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <EvenOddTable<CurrencyFormatterProps> totalCount={props.data.length} {...props} entityType='company' label='CompanyList' columns={columns} rows={rows} disableLocalSort={[...(props.disableLocalSort || []), 'tags', 'codeApe', 'legalStatus']} />
      </Box>
    </>
  );
}

export default CompanyList;
