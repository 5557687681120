import React, { ReactElement } from 'react';
import Grid, { GridBaseProps } from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TitleComponent, { TitleComponentProps } from '@/components/title';

function DashboardSection(props: Partial<TitleComponentProps> & { direction?: GridBaseProps['direction'], children?: ReactElement | ReactElement[] }): ReactElement {

  return (
    <Stack spacing={2}>
      {props.title ? <TitleComponent {...props} title={props.title} size='medium' /> : <Box height={28} /> }
      <Grid container spacing={2} direction={props.direction} overflow='auto'>
        {Array.isArray(props.children) ? props.children?.map(child => (
          <Grid key={child.key} size='grow' minWidth='min-content'>
            {child}
          </Grid>
        )) :
          <Grid size='grow' minWidth='min-content'>
            {props.children}
          </Grid>
        }
      </Grid>
    </Stack>
  );
}

export default DashboardSection;
